import React, { useState } from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './MainPanelHeader.module.css';
import { SearchModeOptions } from '../../../config/configSearch';
import { Button, OutsideClickHandler } from '../../../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../util/routes';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../../ducks/user.duck';
import { getUserType } from '../../../util/userDataExtraction';
import { UserType } from '../../../util/enums';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    isSortByActive,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    noResultsInfo,
  } = props;
  const currentUser = useSelector(currentUserSelector);
  const userType = getUserType(currentUser);
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const classes = classNames(rootClassName || css.root, className);
  const [isOpen, setIsOpen] = useState(false);
  const toggleSearchMode = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            {searchInProgress ? (
              <FormattedMessage id="MainPanelHeader.loadingResults" />
            ) : (
              <FormattedMessage
                id="MainPanelHeader.foundResults"
                values={{ count: resultsCount }}
              />
            )}
          </span>
        </div>
        <OutsideClickHandler
          onOutsideClick={() => setIsOpen(false)}
          className={css.searchModeContainer}
        >
          <Button type="button" onClick={toggleSearchMode} className={css.searchModeButton}>
            <FormattedMessage id="MainPanelHeader.searchMode" />
          </Button>
          {isOpen && (
            <div className={css.searchModeOptions}>
              {SearchModeOptions?.map(({ key, label }) => (
                <div
                  key={key}
                  className={css.searchMode}
                  onClick={() => {
                    setIsOpen(false);
                    history.push(
                      createResourceLocatorString(
                        'SearchPage',
                        routeConfiguration,
                        {},
                        {
                          pub_listingUnderContract: Boolean(key),
                        }
                      )
                    );
                  }}
                >
                  {label}
                </div>
              ))}
            </div>
          )}
        </OutsideClickHandler>
        {isSortByActive ? (
          <div className={css.sortyByWrapper}>
            <span className={css.sortyBy}>
              <FormattedMessage id="MainPanelHeader.sortBy" />
            </span>

            {sortByComponent}
          </div>
        ) : null}
      </div>

      {children}

      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
